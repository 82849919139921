<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-28 16:28:16
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-02 15:10:18
-->
<template>
  <div>
    <memo-box :title="'工单id:' + info.number">
      <div
        slot="header"
        class="btn btn--top"
        v-if="showDetailBtn"
        @click="detail"
      >
        详情
      </div>
      <div slot="body" class="deital">
        <div class="form">
          <div class="form__row" data-title="品牌区域">
            <span>{{ info.brandAreaName }}</span>
          </div>
          <div class="form__row" data-title="店铺名称">
            <span>{{ info.shopName }}</span>
          </div>
          <div class="form__row" data-title="报修等级">
            <div class="tag" v-if="info.repairLevel === 0">非紧急</div>
            <div class="tag tag--wrong" v-else>紧急</div>
          </div>
          <div class="form__row" data-title="报修项目">
            <span>{{ info.repairTypeName }}</span>
          </div>
          <div class="form__row" data-title="报修时间">
            <span>{{ info.createTime }}</span>
          </div>
          <!-- 印章 -->
          <div
            class="icon"
            :style="`background-image: url('${sealIcon}');`"
            v-if="showSeal"
          ></div>
        </div>
        <div class="btns flex-x center right" v-if="showBtns">
          <!-- <button class="btn">查看报价</button> -->
          <button
            v-for="(item, index) in btns"
            :key="index"
            class="btn"
            :class="
              item.type === 'primary'
                ? 'btn--confirm'
                : '' + item.type === 'lock'
                ? 'btn--lock'
                : ''
            "
            @click="clickBtn(item)"
          >
            {{ item.text }}
          </button>
        </div>
        <div class="bottom-text" v-if="bottomText">
          {{ bottomText }}
        </div>
      </div>
    </memo-box>
  </div>
</template>

<script>
import memoBox from "../MemoBox/MemoBox";
export default {
  components: {
    "memo-box": memoBox,
  },
  props: {
    showDetailBtn: {
      // 显示详情按钮
      type: Boolean,
      default: true,
    },

    showSeal: Boolean, // 显示印章
    sealIcon: {
      // 印章图标
      type: String,
      default: require("../../assets/images/user/review_wait.png"),
    },

    showBtns: {
      // 显示按钮组
      type: Boolean,
      default: true,
    },

    btns: {
      // 按钮组
      type: Array,
      default: () => {
        return [
          {
            text: "驳回",
            type: "normal", // normal 普通状态 primary 确认状态
            method: "turnDown",
          },
          {
            text: "通过",
            type: "primary",
            method: "pass",
          },
        ];
      },
    },

    info: Object, // 信息

    bottomText: String, // 底部信息
  },

  methods: {
    clickBtn(e) {
      this.$emit("onclickbtn", { detail: e, info: this.info });
    },

    // 详情按钮
    detail() {
      this.$emit("ondetail", { info: this.info });
    },
  },
};
</script>

<style scoped>
.form {
  margin: 23px;
  padding: 35px 25px 32px;
  background-color: #f8f8f8;
  position: relative;
}

.form__row {
  font-size: 24px;
  color: #999;
}

.form__row::before {
  content: attr(data-title) " :";
  color: #222;
  margin-right: 18px;
}

.form__row + .form__row {
  margin-top: 19px;
}

.tag {
  display: inline-block;
  font-size: 24px;
  padding: 4px 15px;
  background-color: rgba(var(--main-color-rgb), 0.23);
  color: var(--main-color);
}

.tag--wrong {
  background-color: rgba(var(--deputy-color-rgb), 0.23);
  color: var(--deputy-color);
}

.btn {
  padding: 16px 42px;
  font-size: 28px;
  line-height: 28px;
  border-radius: 28px;
  color: #666;
  background-color: white;
  border: 1px solid #ccc;
}

.btns {
  margin-right: 23px;
  margin-bottom: 39px;
}

.btns > .btn + .btn {
  margin-left: 32px;
}

.btn--confirm {
  background-color: var(--main-color);
  color: white;
}

.btn--lock {
  border-color: #ccc;
  color: #ccc;
}

.btn--top {
  background-color: rgba(var(--main-color-rgb), 0.28);
  color: var(--main-color);
  margin-right: 23px;
  border-color: transparent;
}

.icon {
  width: 144px;
  height: 144px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 9px;
  bottom: 19px;
}

.bottom-text {
  margin-bottom: 32px;
  padding: 0 37px;
  color: var(--main-color);
  font-size: 28px;
}

@media screen and (min-width: 900px) {
  .btn {
    padding: 0.6vw 1.2vw;
    font-size: 1.5vw;
    line-height: 1.5vw;
  }

  .btn--top {
    margin-right: 2vw;
  }

  .form {
    padding: 1vw;
    margin: 2vw 1vw;
  }

  .form__row {
    font-size: 1.5vw;
  }

  .form__row::before {
    margin-right: 1.2vw;
  }

  .form__row span {
    font-size: 1.5vw;
  }

  .tag {
    font-size: 1.5vw;
    padding: 0.2vw 1vw;
  }

  .form__row + .form__row {
    margin-top: 1vw;
  }

  .btns {
    margin-bottom: 1vw;
    margin-right: 2vw;
  }

  .btns > .btn + .btn {
    margin-left: 1vw;
  }
}
</style>