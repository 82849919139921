/*
 * @Descripttion: 他人工单接口
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-12-12 22:31:26
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-15 23:41:41
 */
import request from "../request"

// 工单列表
export const orderList = function (data) {
    return request.get('/workStaff/getWorkList', {
        params: data
    })
}

// 工单详情
export const orderDetail = function (data) {
    return request.post('/workStaff/getWorkDetail', data)
}

// 确认工单 (驳回、确认报价)
export const confirmOrder = function (data) {
    return request.post('/review/confirmReview', data)
}

// 工单状态列表
export const orderStatus = function () {
    return request.get('/workStaff/getWorkStatusList')
}