let listLoaderSign;

class pageloader {
    conf = {
        sign: Symbol(),
        api: null, // 请求接口
        format: '', // 数据格式
        list: [],
        listPage: 0,
        listTotalPage: 1,
        listIsLoading: false,
        autoMerge: true,
        totalPageIndex: false,
        showOld: false
    }

    /**
     * 构造分页加载器
     * @param promise api 接口函数
     * @param String format 接口返回数据的列表指向
     * @param Boolean autoMerge 自动合并完整列表 true返回历史列表+当前列表 false返回当前列表* 
     * @param Stirng totalPageIndex 总页数的接口指向
     * @param Boolean showOld 显示原始数据
     */
    constructor(api, format = 'data.order', autoMerge = true, totalPageIndex = 'data.data.count_page', showOld = false) {
        this.conf.api = api;
        this.conf.format = format;
        this.conf.autoMerge = autoMerge;
        this.conf.totalPageIndex = totalPageIndex;
        this.conf.showOld = showOld
    }

    // 加载
    load(data) {
        listLoaderSign = this.conf.sign;
        return new Promise((resolve, reject) => {
            if (this.conf.listIsLoading) return resolve(false);
            if (this.conf.listPage >= this.conf.listTotalPage) return resolve(false);
            this.conf.listIsLoading = true;

            let saveSign = this.conf.sign;
            this.conf.api({
                limit: 10,
                ...data,
                page: this.conf.listPage + 1,
            }).then(res => {
                if (listLoaderSign != saveSign) return resolve(false)

                let list = this.conf.list,
                    resultList = getFormatData(res, this.conf.format),
                    newList = list.concat(resultList);

                this.conf.list = newList;
                this.conf.listIsLoading = false;
                this.conf.listTotalPage = getFormatData(res, this.conf.totalPageIndex);
                this.conf.listPage += 1;

                if (this.conf.autoMerge) {
                    if (this.conf.showOld) {
                        resolve({
                            list: newList,
                            result: res
                        })
                    } else {
                        resolve(newList)
                    }
                } else {
                    if (this.conf.showOld) {
                        resolve({
                            list:resultList,
                            result:res
                        })
                    }else{
                        resolve(resultList)
                    }
                }

            }).catch(res => {
                reject(res)
            })
        })
    }

    // 重载
    reLoad() {
        this.conf.list = [];
        this.conf.listIsLoading = false;
        this.conf.listTotalPage = 1;
        this.conf.listPage = 0;

        // 刷新sign
        const sign = Symbol();
        this.conf.sign = sign;
        listLoaderSign = sign;
    }
}

// 获取层级下数据
function getFormatData(data, format) {
    let formatList = format.split('.');

    let newData = JSON.parse(JSON.stringify(data));

    formatList.forEach(item => {
        newData = newData[item];
    })

    return newData
}

export default pageloader;